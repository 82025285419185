import * as React from 'react';
import { Container, Grid, List, ListItem, Typography, Link as MuiLink } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Link from 'next/link';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useRouter } from 'next/router';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../lib/useAuth';
import bannerImg from './bannerShape.png';
import niwLogo from '../../public/images/niw_reversed.png';
import fbSVG from '../../public/images/ico-fb.svg';
import twSVG from '../../public/images/ico-twitter.svg';
import inSVG from '../../public/images/ico-linkedin.svg';
import youtubeSVG from '../../public/images/ico-youtube.svg';
import vimeoSVG from '../../public/images/ico-vimeo.svg';
import { initFalse } from '../../utils/constants';
import { JsEvents } from '../../utils/enums';


const socialLinks = [
  {img: fbSVG, alt: 'facebook', link: 'https://www.facebook.com/NIWCompanies'},
  {img: twSVG, alt: 'twitter', link: 'https://twitter.com/corpniw'},
  {img: inSVG, alt: 'linkedin', link: 'https://www.linkedin.com/company/3250930'},
  {img: youtubeSVG, alt: 'youtube', link: 'https://www.youtube.com/channel/UCxTYQyaXSX2P-OzaxLiqLDw'},
  {img: vimeoSVG, alt: 'vimeo', link: 'https://vimeo.com/user92317327'},
];

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      background: '#01305F',
      width: '100%',
      padding: '70px 0 120px',
      [ theme.breakpoints.down(767) ]: {
        padding: '40px 0 120px',
      },

    },
    title: {
      borderBottom: '1.5px solid #D6A107',
      width: '100%',
      float: 'left',
      paddingBottom: 10,
    },
    socialIcons: {
      display: "flex",
      width: 'auto',
      [ theme.breakpoints.down(767) ]: {
        display:'inline-flex !important',
        margin: '0 auto',
        width: '200px',
        float: 'none !important',
      }
    },
    socialIcon: {
      width: "auto !important",
      padding: '0 !important',
      marginRight: 10,
      [ theme.breakpoints.down(767) ]: {
        marginRight: 10,
      },
    },
    textPrimary: {
      color: '#01305F'
    },
    backToTop: {
      backgroundColor: '#e9eff5',
      padding: '20px 0',
      position: 'relative'
    },
    footerfixed: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      background: '#203053',
      zIndex: 99,
      cursor: 'pointer',
      textAlign: 'center',
    },
    footerBanner: {
      left: 0,
      right: 0,
      bottom: 20,
      padding: 0,
      zIndex: 99,
      position: 'fixed',
      background: '#ffffff',
      width: 800,
      margin: '0 auto',
      borderRadius: 10,
      border: '3px solid #62DD97',
      cursor: 'pointer',
      [ theme.breakpoints.down(992) ]: {
        display: 'none',
      },

    },
    estimateBtnBanner: {
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0,
      zIndex: 99,
      position: 'fixed',
      margin: '0 auto',
      textAlign: 'center',
      display: 'none',
      [ theme.breakpoints.down(992) ]: {
        display: 'block',
      },
    }
  }),
);


const FOOTER_SHOW_MIN_HEIGHT = typeof window !== "undefined" && window.innerWidth <= 992 ? 900 : 500;

const Footer = (props: any) => {
  const {scollTop, isIntroVideoComplete, indexPage, setEditPageDetailOpen} = props;
  const [footerVisible, setFooterVisible] = React.useState(initFalse);
  const classes = useStyles();
  const router = useRouter();
  const [strategyFinder, setStrategyFinder] = React.useState<boolean>(initFalse);
  const {landing} = router.query;
  const {user} = useAuth();
  const isLoggedIn: any = user && user.isLoggedIn;
  const isAgentRegistrationPage = router.pathname === '/agent-resources/register';

  const isKaiZenPage = router.pathname === '/retirement-planning/kai-zen';

  React.useEffect(() => {
    let unMounted = initFalse;
    if (unMounted) {
      return;
    }

    if (isIntroVideoComplete && indexPage) {
      setStrategyFinder(!initFalse);
    }

    if (!indexPage) {
      setStrategyFinder(!initFalse);
    }

    if ("/privacy-policy" === router.pathname || "/find-niw-strategy" === router.pathname) {
      setStrategyFinder(initFalse);
    }
    return () => {
      unMounted = !initFalse;
    }
  }, [isIntroVideoComplete]);

  React.useEffect(() => {
    window.addEventListener(JsEvents.Scroll, (event: any) => {
      if (event && event.target) {
        if (event && event.target &&
          event.target.scrollTop && event.target.scrollTop > FOOTER_SHOW_MIN_HEIGHT) {
          setFooterVisible(!initFalse);
        } else {
          setFooterVisible(initFalse);
        }
      }
    }, !initFalse);

    return () => {
      window.removeEventListener(JsEvents.Scroll, () => {
      }, !initFalse);
    }
  }, []);

  return (
    <>
      <div className="clearFix"/>
      <div className={classes.backToTop}>
        <Container maxWidth="lg" className="textCenter ">
          <Typography
            onClick={scollTop}
            variant="caption"
            align="center"
            className={classes.textPrimary + " " + " mb0"}
            style={{width: 120, margin: '0 auto', display: 'flex', cursor: 'pointer',alignItems:'center'}}>
            Back to Top
            <KeyboardArrowUpIcon style={{fontSize: 30, float: 'right'}}/>
          </Typography>
          {isLoggedIn &&
            <Button className="floatRight mb20 mt20 editPageMeta" size="small" variant="contained" color="primary"
                    onClick={() => {
                      setEditPageDetailOpen(!initFalse)
                    }}>
              Edit Page Meta Details
            </Button>
          }
        </Container>
      </div>
      <footer className={classes.footer}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={3} className="xsOrderLast">
              <img loading="lazy" width="180px" src={niwLogo} alt="Niw Logo" className="mb15"/>
              <Typography variant="caption" align="left"
                          className="textWhite floatLeft  w100 "> NIW’s
                mission is
                to meaningfully and
                sustainably improve the estate or
                retirement situation of
                our clients while changing the culture of the life insurance industry to more
                sustainable, client-centered solutions.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="caption" component="strong"
                          className={classes.title + " " + " textWhite "}>STRATEGIES</Typography>
              <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                  <List component="ul" aria-label="secondary mailbox folders"
                        className="textWhite cursor footerLinks">
                    <ListItem>
                      <Link href={'/retirement-planning/kai-zen'}>
                        <Typography variant="caption">Kai-Zen<sup>®</sup></Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/business-planning/tri-zen'}>
                        <Typography variant="caption">Tri-Zen<sup>®</sup></Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/retirement-planning/kai-zen'}>
                        <Typography variant="caption">Leveraged Savings Plan</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/retirement-planning/enhanced-split-dollar'}>
                        <Typography variant="caption">Enhanced Split Dollar</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/business-planning/cashstream'}>
                        <Typography variant="caption">Cash Stream</Typography>
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <List component="ul" aria-label="secondary mailbox folders"
                        className="textWhite cursor footerLinks">
                    <ListItem>
                      <Link href={'/estate-planning/premium-finance-rescue'}>
                        <Typography variant="caption">Premium Finance Rescue</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/estate-planning/life-strategies'}>
                        <Typography variant="caption">Life Strategies</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/estate-planning/multi-gen'}>
                        <Typography variant="caption">Multi-Gen</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/estate-planning/trust-revitalization'}>
                        <Typography variant="caption">Trust Revitalization</Typography>
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link href={'/find-niw-strategy'}>
                        <Typography variant="caption">Find Your Strategy</Typography>
                      </Link>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Typography variant="caption" component="strong" className={classes.title + " " + " textWhite "}>
                RESOURCES & COMPANY
              </Typography>
              <List component="ul" aria-label="secondary mailbox folders"
                    className="textWhite floatLeft w100 cursor footerLinks">
                <ListItem>
                  <Link href={'/myilia'}>
                    <Typography variant="caption">About ilia<sup>®</sup></Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href={'/about-niw'}>
                    <Typography variant="caption">About NIW</Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href={'/about-niw/news-and-social'}>
                    <Typography variant="caption">News & Social</Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href={'/contact'}>
                    <Typography variant="caption">Contact Us</Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <MuiLink href={'https://www.simplicitygroup.com/privacy-policy/'} className="textWhite" target="_blank">
                    <Typography variant="caption">Privacy Policy</Typography>
                  </MuiLink>
                </ListItem>
                <ListItem>
                  <Link href={'/wire-instructions'}>
                    <Typography variant="caption">Wire Instructions</Typography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={3} md={3} lg={4}/>
            <Grid item xs={12} sm={9} md={9} lg={6}>
              <div className="floatRight textCenter w100sm">
                <Typography variant="caption"
                            className="floatLeft mr30 textWhite mt10 floatInherit w100sm mr0sm">Copyright&nbsp;
                  {new Date().getFullYear()} NIW | All Rights Reserved
                </Typography>
                <List className={classes.socialIcons + " " + " footerLinks "}>
                  {
                    socialLinks.map((o, idx) => (
                      <ListItem key={idx} className={classes.socialIcon}>
                        <Link href={o.link}>
                          <a target="_blank" rel="noreferrer">
                            <img src={o.img} alt={o.alt} width={28} height={29}/>
                          </a>
                        </Link>
                      </ListItem>
                    ))
                  }
                </List>
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
      {
        strategyFinder && !isKaiZenPage &&
        <div
          className={`${classes.footerfixed} ${!isAgentRegistrationPage && footerVisible ? 'footerVisible textCenter' : ' footerhideen textCenter '}`}>
          <Link href="/find-niw-strategy">
            <Typography variant="h3" align="center" style={{cursor: "pointer", lineHeight: '40px'}}
                        className="textWhite mb0 mt15">
              Find your strategy <ArrowForwardIcon style={{fontSize: 24}}/>
            </Typography>
          </Link>
          <Typography variant="caption" align="center" className="textWhite f12">
            See if you qualify in less than 2 minutes.
          </Typography>
        </div>
      }

      {
        !landing && isKaiZenPage &&
        <>
          <div
            className={`${classes.footerBanner} ${footerVisible ? 'bannerVisible' : 'bannerHideen'}`}>
            <div onClick={scollTop}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{width: '50%', paddingLeft: 10}}>
                      <Typography component="strong" variant="body1"
                                  className="mb0 textHighLight floatLeft">GET STARTED</Typography>
                      <Typography variant="h4" align="left"
                                  className="mb0 floatLeft w100">Free Benefits Estimate</Typography>
                    </span>
                <span style={{
                  background: '#01305F',
                  display: 'flex',
                  width: '50%',
                  justifyContent: 'space-between',
                  padding: 30,
                  position: 'relative'
                }}>
                      <span>
                        <img style={{position: 'absolute', left: '-72px', top: '-5px'}} src={bannerImg} alt="banner"/>
                        <Typography component="strong" variant="subtitle1"
                                    className="textHighLight f28">60 - 100%</Typography>
                        <Typography variant="body1" className="textWhite">More for Retirement</Typography>
                      </span>
                      <ArrowForwardIcon className="textWhite" style={{fontSize: 40, float: 'right'}}/>
                    </span>
              </div>
            </div>
          </div>
          <div onClick={scollTop}
               className={`${classes.estimateBtnBanner} ${footerVisible ? 'bannerVisible' : ' bannerHideen'}`}>
            <Button
              className="mt20 customBtn mb20 glow"
              variant="contained"
              type={"submit"}
              endIcon={<ArrowForwardIcon/>}
            >
              Get Free Estimate
            </Button>
          </div>
        </>
      }

    </>
  );
};

export default Footer;
