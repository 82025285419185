import React from "react";
import { Formik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, Button } from "@material-ui/core";
import axios from "axios";
import { ErrorCommon, Label, TextField, TextFieldCommon } from "../formikFormInputs";
import { useStyles } from "./AddEditCarousel";
import { externalServerUrl } from "../../server/constants";
import DialogHeader from "../dialog/DialogHeader";

type PropsType = {
  editPageOpen: boolean,
  setEditPageDetailOpen: Function,
  keywords: string,
  title: string,
  description: string,
  pageId: number,
  setMessage: Function,
  setSnackbarOpen: Function,
  setSeverity: Function
}

const fieldObj = [{
  id: "title",
  name: "title",
  component: TextField,
  placeholder: "Title",
  label: <Label label={"Title"} required={true}/>,
  rowSize: 6
}, {
  id: "keywords",
  name: "keywords",
  component: TextField,
  placeholder: "Keywords",
  label: <Label label={"Keywords"} required={true}/>,
  rowSize: 6
}, {
  id: "description",
  name: "description",
  component: TextField,
  placeholder: "Description",
  label: <Label label={"Description"} required={true}/>
}];
const titleMaxLength = 70;
const keywordMaxLength = 100;
const descriptionMaxLength = 160;

export default function AddEditPageDetail(props: PropsType) {
  const {
    editPageOpen,
    setEditPageDetailOpen,
    keywords,
    title,
    description,
    pageId,
    setMessage,
    setSnackbarOpen,
    setSeverity
  } = props;
  const initialValues = {
    title,
    keywords,
    description
  };
  const [initialVal, setInitialVal] = React.useState(initialValues);
  const classes = useStyles();

  const submitFormik = async (values: any) => {
    const { title, keywords, description } = values;
    const url = `${externalServerUrl}/api/pages/${pageId}`;
    let payload = {
      id: pageId,
      title: title.trim(),
      keywords: keywords.trim(),
      description: description.trim()
    };
    setInitialVal({ title: title.trim(), keywords: keywords.trim(), description: description.trim() });

    try {
      await axios.put(url, payload);
      setEditPageDetailOpen(false);
      setMessage("Meta Details Updated Successfully!");
      setSnackbarOpen(true);
      setSeverity("success");
    } catch (error: any) {
      setMessage(error && error.response && error.response.data && error.response.data.message);
      setEditPageDetailOpen(false);
      setSnackbarOpen(true);
      setSeverity("error");
    }
  };

  const validate = ((value: any) => {
    const errors: any = {};
    if (value.title === "") {
      errors.title = "Required";
    }
    if (value.keywords === "") {
      errors.keywords = "Required";
    }
    if (value.description === "") {
      errors.description = "Required";
    }
    if (value.title && !value.title.trim()) {
      errors.title = "Required";
    }
    if (value.keywords && !value.keywords.trim()) {
      errors.keywords = "Required";
    }
    if (value.description && !value.description.trim()) {
      errors.description = "Required";
    }
    if (value.title && value.title.length > titleMaxLength) {
      errors.title = `Must be less than ${titleMaxLength} characters`;
    }
    if (value.keywords && value.keywords.length > keywordMaxLength) {
      errors.keywords = `Must be less than ${keywordMaxLength} characters`;
    }
    if (value.description && value.description.length > descriptionMaxLength) {
      errors.description = `Must be less than ${descriptionMaxLength} characters`;
    }
    return errors;
  });

  return (
    <Dialog
      onClose={() => setEditPageDetailOpen(false)}
      maxWidth="md"
      aria-labelledby="customized-dialog-title"
      open={editPageOpen}
    >
      <DialogHeader onClose={() => setEditPageDetailOpen(false)}>
        Update Page Meta Details
      </DialogHeader>
      <DialogContent dividers>
        <Formik
          initialValues={initialVal}
          validate={(values: any) => validate(values)}
          onSubmit={(values: any, {}: any) => submitFormik(values)}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              dirty
            }: any) =>
            (
              <form onSubmit={handleSubmit} autoComplete="false">
                <Grid container spacing={3}>
                  {
                    fieldObj.map((field, index) => {
                      return (
                        <Grid item
                              xs={field.rowSize === 6 ? 6 : 12}
                              key={index}>
                          <TextFieldCommon
                            {...field}
                            values={values}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorCommon errors={errors} name={field.name} touched={touched}/>
                        </Grid>)
                    })
                  }
                </Grid>
                <br/>
                <Button
                  className={classes.floatRight + " " + classes.mt20}
                  variant="contained"
                  color="primary"
                  disabled={(isSubmitting || !dirty || Object.keys(errors).length)}
                  type="submit"
                >
                  Update
                </Button>
              </form>
            )
          }
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
