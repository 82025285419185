import React from 'react';
import loader from '../style/Images/loader_nw.gif';

const Loader = () => {
  return (
    <div>
      <img style={{position: 'absolute', top: '50%', left: 0, right: 0, margin: '0 auto'}} src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
