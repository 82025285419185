import React, { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
import { Formik } from 'formik';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Avatar, Button, Dialog, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { carouselBucketURL, carouselDirectory, externalServerUrl } from '../../server/constants';
import { ErrorCommon, Label, TextField, TextFieldCommon } from '../formikFormInputs';
import { DialogContent, DialogHeader } from '../dialog';
import { consoleLog, errorLog } from '../../utils/commonUtils';


const CropCircularImage = dynamic(() => import('../Editor/CropCircularImage'));


export const useStyles = makeStyles({
  floatRight: {
    float: 'right',
  },
  mt20: {
    marginTop: 20
  },
});

export default function AddEditCarousel(props: any) {
  const fileUploader = useRef<any>(null);
  const {carouselId} = props;
  const [popUpTitle, setPopUpTitle] = React.useState<string>('');
  const {handleCarouselEditorClose, openCarouselEditor, individualCarousalData, getCarouselVideos} = props;
  const [initialValues, setInitialValues] = React.useState<any>({
    url: '',
    title: '',
    description: '',
    image: '',
    name: '',
    designation: '',
    strategyTestimonial: '',
    img: ''
  });
  const [uploadFile, setUploadFile] = useState<any>(undefined);
  const [imgName, setImgName] = useState<string>('');
  const [cropdialog, setCropDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState<string | any>('success');
  const classes = useStyles();


  React.useEffect(() => {
    setImgName('');
    setMessage('');
    if (individualCarousalData && individualCarousalData.id) {
      setImgName(individualCarousalData.image);
      setInitialValues({
        id: individualCarousalData.id,
        url: individualCarousalData.url,
        title: individualCarousalData.title,
        description: individualCarousalData.description,
        image: individualCarousalData.image,
        name: individualCarousalData.name,
        designation: individualCarousalData.designation,
        strategyTestimonial: individualCarousalData.strategyTestimonial,
        img: individualCarousalData.image,
      });
      setPopUpTitle('Update');
    } else {
      setInitialValues({
        url: '',
        title: '',
        description: '',
        image: '',
        name: '',
        designation: '',
        strategyTestimonial: '',
        img: ''
      });
      setPopUpTitle('Add');
    }
  }, [individualCarousalData]);
  const hidden = (props: any) => {
    return (
      <input {...props.input} type="hidden" />
    )
  };
  const fieldObj = [
    {
      id: 'title',
      name: 'title',
      component: TextField,
      placeholder: 'Title',
      label: <Label label={'Title'} required={true} />,
      rowSize: 6
    }, {
      id: 'name',
      name: 'name',
      component: TextField,
      placeholder: 'Name',
      label: <Label label={'Name'} required={true} />,
      rowSize: 6
    },
    {
      id: 'designation',
      name: 'designation',
      component: TextField,
      placeholder: 'Designation',
      label: <Label label={'Designation'} required={true} />,
      rowSize: 6
    },
    {
      id: 'url',
      name: 'url',
      component: TextField,
      placeholder: 'URL',
      label: <Label label={'URL'} required={true} />,
      rowSize: 6
    },
    {
      id: 'strategyTestimonial',
      name: 'strategyTestimonial',
      component: TextField,
      placeholder: 'Strategy Testimonial',
      label: <Label label={'Strategy Testimonial'} required={true} />,
      rowSize: 6
    },
    {
      id: 'description',
      name: 'description',
      component: TextField,
      multiline: true,
      rows: 5,
      label: <Label label={'Description'} />,
      placeholder: 'Description',
      rowSize: 12
    },
    {
      id: 'img',
      name: 'img',
      component: hidden,
      rowSize: 12
    }
  ];

  const submitFormik = async (values: any, setSubmitting: any, resetForm: any) => {
    let payload;
    let url;
    if (values.id) {
      payload = {
        id: values.id,
        url: values.url,
        title: values.title,
        description: values.description,
        image: imgName,
        name: values.name,
        designation: values.designation,
        strategyTestimonial: values.strategyTestimonial
      };
      setImgName(imgName);
      url = `${externalServerUrl}/api/carousel/updateCarouselVideoById`;
    } else {
      payload = {
        carouselId: carouselId,
        url: values.url,
        title: values.title,
        description: values.description,
        image: imgName,
        name: values.name,
        designation: values.designation,
        strategyTestimonial: values.strategyTestimonial
      };
      url = `${externalServerUrl}/api/carousel/addCarousal`;
    }
    await axios.post(url, payload)
      .then((response) => {
        resetForm();
        getCarouselVideos(carouselId);
        setTimeout(() => {
          handleCarouselEditorClose();
        }, 2000);
        setImgName('');
        setMessage(values.id ? 'Video Updated Successfully!' : 'Video Added Successfully!');
        setSeverity('success');
        setSnackbarOpen(true);
        consoleLog('response', response)
      })
      .catch((error: any) => {
        setSnackbarOpen(true);
        setSeverity('error');
        setMessage(error && error.message);
        errorLog(error);
      });
    consoleLog('form submitted', values, setSubmitting, resetForm);
  };

  const validate = ((value: any) => {
    const errors: any = {};
    if (!value.url.trim()) {
      errors.url = 'Required';
    }
    if (!value.title.trim()) {
      errors.title = 'Required';
    }
    if (!value.description.trim()) {
      errors.description = 'Required';
    }
    if (!value.name.trim()) {
      errors.name = 'Required';
    }
    if (!value.designation.trim()) {
      errors.designation = 'Required';
    }
    if (!value.strategyTestimonial.trim()) {
      errors.strategyTestimonial = 'Required';
    }
    if (!value.img) {
      errors.img = 'Please upload image';
    }
    return errors;
  });

  const handleFileUpload = async (file: File) => {
    let filename: any = uploadFile.name.split('.').join('-' + Date.now() + '.');
    axios.get(`${externalServerUrl}/api/aws/signUrl?dirName=${carouselDirectory}&objectName=${filename}&contentType=${file.type}`)
      .then((result) => {
        const signedUrl = result.data.signedUrl;
        const publicUrl = result.data.publicUrl;
        const options = {
          headers: {
            'Content-Type': file.type
          }
        };
        consoleLog('publicUrl', publicUrl);
        /*setImgSrc(`${carouselBucketURL}/${uploadFile.name}`);*/
        setMessage('Image Uploaded Successfully!');
        setSeverity('success');
        setSnackbarOpen(true);
        return axios.put(signedUrl, file, options);
      }).then(() => {
      setImgName(filename);
      handleCropperClose();
    }).catch((error) => {
      errorLog('error-handleFileUpload', error);
      setMessage(error && error.response && error.response.data && error.response.data.message);
      setSeverity('error');
      setSnackbarOpen(true);
    });
  };

  const handleCropperOpen = () => {
    setCropDialog(true);
  };

  const handleCropperClose = () => {
    setCropDialog(false);
  };

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    consoleLog(event);
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Dialog
        onClose={handleCarouselEditorClose}
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        open={openCarouselEditor}
      >
        {
          !!message &&
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={4000}
            onClose={handleSnackBarClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          >
            <Alert style={{zIndex: 999999,}} onClose={handleSnackBarClose} severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        }
        <DialogHeader onClose={handleCarouselEditorClose}>
          {popUpTitle}&nbsp;Video
        </DialogHeader>
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            validate={(values: any) => validate(values)}
            onSubmit={(values: any, {setSubmitting, resetForm}: any) => submitFormik(values, setSubmitting, resetForm)}
          >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                dirty
              }: any) =>
              (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    {
                      fieldObj.map((field, index) => {
                        return (
                          <Grid
                            item
                            xs={field.rowSize === 12 ? 12 : field.rowSize === 6 ? 6 : field.rowSize === 4 ? 4 : 4}
                            key={index}
                          >
                            <TextFieldCommon
                              {...field} values={values} onChange={handleChange} onBlur={handleBlur}
                            />
                            <ErrorCommon errors={errors} name={field.name} touched={touched} />
                          </Grid>)
                      })
                    }
                    <Grid item xs={6}>
                      <input
                        ref={fileUploader}
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        style={{display: 'block'}}
                        onClick={(e: any) => (e.target.value = null)}
                        onChange={(event: any) => {
                          if (event.target.files![ 0 ]) {
                            setFieldValue('img', event.target.files![ 0 ]);
                            setUploadFile(event.target.files![ 0 ]);
                            handleCropperOpen();
                          }
                        }}
                      />
                    </Grid>
                    {
                      cropdialog &&
                      <CropCircularImage
                        selectedFile={uploadFile} fileUpload={handleFileUpload} handleClose={handleCropperClose}
                      />
                    }
                  </Grid>
                  <br />
                  <Button
                    className={classes.floatRight + ' ' + classes.mt20}
                    variant="contained"
                    color="primary"
                    disabled={(isSubmitting || !dirty)}
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                  >
                    {popUpTitle}&nbsp;Carousel
                  </Button>
                </form>
              )
            }
          </Formik>
          {
            imgName &&
            <Grid item xs={2}>
              <Avatar alt="Remy Sharp" src={`${carouselBucketURL}/${imgName}`} style={{height: 100, width: 100}} />
            </Grid>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
}
